import { Icon } from '@iconify/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function PageTitle({ content }) {

    const navigate = useNavigate()

    return (
        <div style={{ backgroundColor: '#FF430D'}} className="text-center text-light d-flex align-items-center py-2">
            <div>
                <span className='pointer' onClick={() => navigate(-1)}><Icon width={30} icon={'solar:alt-arrow-left-linear'} /></span>
            </div>
            <div>
                {
                    window.innerWidth < 1000 ? <span className='fs-5 ms-3'>{content}</span> :
                        <span className='fs-4'>{content}</span>
                }
            </div>
        </div>
    )
}

export default PageTitle