import { Icon } from '@iconify/react';
import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CUITClientValidation({ modalIngresoCuil, isLoading, setIsLoading, handleClose, cuilIngresado, setCuilIngresado, validarCuilIngresado }) {


    return (
        <div>
            <Modal className='pt-5' show={modalIngresoCuil}>

                <Modal.Header>
                    <Modal.Title>
                        <span>Ingresar con CUIL/CUIT</span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='text-start'>
                    <p style={{ fontSize: 13 }}>
                        Escribe el CUIL/CUIT asociado a Banner Director
                    </p>
                    <Form.Control onChange={(e) => setCuilIngresado(e.target.value)} placeholder='CUIL/CUIT' type='text' />


                    {
                        !isLoading ?
                            <div className="text-center mt-2">
                                <button onClick={validarCuilIngresado} style={{ border: 'none', backgroundColor: 'orangered' }} className="text-light rounded py-1">Validar</button>
                            </div> :

                            <div className="text-center mt-2">
                                <Spinner style={{ color: 'grey' }} animation="grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                    }
                </Modal.Body>


                <Modal.Footer>
                    <Icon className='pointer' onClick={handleClose} icon={'solar:close-circle-outline'} width={25} />
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CUITClientValidation