import { Icon } from '@iconify/react'
import React from 'react'

function TituloMasLogo({solucion}) {
    return (
        <div className="d-flex align-items-center">
            <div className="my-shadow text-center d-flex justify-content-center align-items-center" style={{ width: 50, height: 50, backgroundColor: '#DA3E00', borderRadius: 50, zIndex: 2 }}>
                <Icon icon={'ic:outline-wifi-off'} width={30} color="white" />
            </div>

            <div style={{ backgroundColor: '#DA3E00', height: 35, marginLeft: -20, zIndex: 1, borderRadius: 44 }} className="d-flex align-items-center col-11">
                <span className="px-4 text-light" style={{ fontSize: 13 }}>{solucion}</span>
            </div>
        </div>
    )
}

export default TituloMasLogo