import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import gifMac from "../../../../Assets/gif-mac-address.gif"
import { useNavigate } from 'react-router-dom';

function ModalMacInorrect({ modalMacIncorrect, setModalMacIncorrect, handleIngresarConCuil }) {

    const navigate = useNavigate()


    return (
        <Modal className='pt-5' show={modalMacIncorrect}>

            <Modal.Header>
                <Modal.Title>
                    <span>Código MAC incorrecto</span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-start'>

                <p style={{ fontSize: 13 }}>No pudimos encontrar el Código MAC de tu equipo en nuestra base de datos. Recuerda que es muy importante para saber qué solución brindarte.</p>

                <div className="text-center">
                    <img className='rounded' src={gifMac} width={'100%'} />
                </div>

                <div className='text-center'>

                    <Button onClick={() => setModalMacIncorrect(false)} className='mt-2 me-1' style={{ backgroundColor: '#D70926', border: 'none' }} size='sm'>Intentar de nuevo</Button>
                    <Button onClick={handleIngresarConCuil} className='mt-2' style={{ backgroundColor: '#061336', border: 'none' }} size='sm'>Ingresar con CUIL/CUIT</Button>

                </div>
            </Modal.Body>


            <Modal.Footer>
                {/* <Icon className='pointer' onClick={handleClose} icon={'solar:close-circle-outline'} width={25} /> */}
            </Modal.Footer>
        </Modal >
    )
}

export default ModalMacInorrect