import React from 'react'
import flecha_btn from "../../../../Assets/Flecha_btn.svg"
import { useNavigate } from 'react-router-dom'

function CadaSolucion({ solucion }) {

    const navigate = useNavigate()

    return (
        <div className={`${window.innerWidth < 1000 ? 'col-11' : 'col-5'} mx-auto p-3 mb-3 mt-3 rounded my-shadow`} style={{ backgroundColor: '#FAFAFA' }}>
            <div className="col-12" style={{ backgroundColor: '#DA3E00', borderRadius: 44 }}>
                <div className="py-2 px-3">
                    <span style={{ fontSize: 14 }} className="text-light">{solucion.titulo}</span>
                </div>
            </div>
            <div className="col-12 mt-2" style={{ fontSize: 14 }}>
                <ul>
                    {
                        solucion.items.map((item, index) => (
                            <li key={index}>
                                <span>
                                    {item}
                                </span>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="text-end">
                <button onClick={() => navigate(solucion.navegacion)} className="py-1 my-shadow" style={{ backgroundColor: 'white', borderRadius: 44, border: 'none' }}>
                    <span style={{ color: '#DA3E00', fontSize: 14 }}>Solución</span>
                    <img className="ms-2" src={flecha_btn} />
                </button>
                <div>
                </div>
            </div>
        </div>
    )
}

export default CadaSolucion