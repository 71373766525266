import { createContext, useEffect, useState } from "react";

const AppContext = createContext()

const Context = ({ children }) => {

    const [chatId, setChatId] = useState('')

    const [problemCategory, setProblemCategory] = useState('')

    const data = { problemCategory, setProblemCategory }
    return <AppContext.Provider value={data}>{children}</AppContext.Provider>
}

export default Context
export { AppContext }