import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import gif_ebd_2antenas from "../../../../Assets/gif_ebd_2antenas.gif"
import gif_cube from "../../../../Assets/gif_ebd_cube.gif"
import gif_d3 from "../../../../Assets/gif_d3.gif"

function DropdownEquipos() {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header><span style={{ fontSize: 14 }}>¿Dónde encuentro el Código MAC Address?</span></Accordion.Header>
                <Accordion.Body>
                    <Carousel variant='dark'>
                        <Carousel.Item>
                            <img className='rounded' src={gif_cube} width={'100%'} />
                            <Carousel.Caption>
                                <h5 className='text-secondary'>Cube</h5>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className='rounded' src={gif_ebd_2antenas} width={'100%'} />
                            <Carousel.Caption>
                                <h5 className='text-secondary'>Dos antenas</h5>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className='rounded' src={gif_d3} width={'100%'} />
                            <Carousel.Caption>
                                <h5 className='text-secondary'>Digital Desk Display</h5>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default DropdownEquipos