import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Client/Home/Home';
import Chatbot from "./Pages/Client/Chatbot/Chatbot"
import ClientValidation from './Pages/Client/ClientValidation/ClientValidation';
import QuickSolutionDetail from './Pages/Client/Solutions/QuickSolutionDetail/QuickSolutionDetail';
import BannerAgentLogin from './Pages/Admin/BannerAgentLogin/BannerAgentLogin';
import QuickSolutions from './Pages/Client/Solutions/QuickSolutions/QuickSolutions';
import ChatManager from "./Pages/Admin/ChatManager/ChatManager"
import Context from './Utils/Context';
import Pruebas from './Pages/Pruebas/Pruebas';

function App() {
  return (
    <BrowserRouter>
      <Context>
        <Routes>

          <Route path='/' element={<Home />} />

          <Route path='/pruebas' element={<Pruebas />} />

          <Route path='/soluciones' element={<QuickSolutions />} />
          <Route path='/soluciones/:id' element={<QuickSolutionDetail />} />

          <Route path='/validacioncliente' element={<ClientValidation />} />
          {/* <Route path='/asistenciachat/:id' element={<Chatbot />} /> */}

          {/* <Route path='/admin' element={<BannerAgentLogin />} /> */}
          {/* <Route path='/chatsmanager' element={<ChatManager />} /> */}

        </Routes>
      </Context>
    </BrowserRouter>
  );
}

export default App;
