import { Icon } from '@iconify/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo_azul from "../Assets/logo_azul.svg"

function MenuNav() {

    const navigate = useNavigate()

    const goToPage = (page) => {
        window.location.href = page
    }

    return (

        window.innerWidth < 1000 ?

            <>
                <div style={{ height: 100 }}>
                </div>
                <div style={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    height: "60px",
                }}>
                    <div style={{ borderRadius: "10px 10px" }} className="bg-shadow bg-nav d-flex justify-content-evenly my-shadow col-12 mx-auto rounded-t" >
                        <div className="py-2 text-center">
                            <Icon className='hover-btn-nav' onClick={() => navigate(-1)} icon={'solar:round-alt-arrow-left-bold'} width={28} color="#061337" />
                            <div>
                                <span style={{ color: '#061337', fontSize: 11 }}>Atrás</span>
                            </div>
                        </div>
                        <div className="py-2 mt-1 text-center">
                            <img className='hover-btn-nav' onClick={() => navigate("/")} width={55} src={logo_azul} />
                        </div>
                        <div className="py-2 text-center">
                            <Icon onClick={() => goToPage('https://web.bannerdirector.com/contacto')} icon={'solar:question-square-bold'} width={28} color="#061337" />
                            <div>
                                <span style={{ color: '#061337', fontSize: 11 }}>FAQ</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            :

            <>
                <div style={{ height: 100 }}>
                </div>
                <div className="text-center col-6 mx-auto d-flex justify-content-center">
                    <div style={{
                        position: 'fixed',
                        bottom: 0,       /* Alinea el elemento en la parte inferior de la pantalla */
                        width: '50%',     /* Opcional: Esto hará que el elemento se extienda a lo ancho de la pantalla */
                        height: 60
                    }}>
                        <div style={{ borderRadius: "10px 10px" }} className="bg-shadow bg-nav d-flex justify-content-evenly my-shadow col-12 mx-auto rounded-t" >
                            <div className="py-2 text-center">
                                <Icon className='hover-btn-nav pointer' onClick={() => navigate(-1)} icon={'solar:round-alt-arrow-left-bold'} width={28} color="#061337" />
                                <div>
                                    <span style={{ color: '#061337', fontSize: 14 }}>Atrás</span>
                                </div>
                            </div>
                            <div className="pb-4 m-1 text-center">
                                <img className='pointer' onClick={() => navigate("/")} width={75} src={logo_azul} />
                            </div>
                            <div className="py-2 text-center">
                                <Icon className='pointer' onClick={() => goToPage('https://web.bannerdirector.com/contacto')} icon={'solar:question-square-bold'} width={28} color="#061337" />
                                <div>
                                    <span style={{ color: '#061337', fontSize: 14 }}>FAQ</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>


    )
}

export default MenuNav