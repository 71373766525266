import { Icon } from '@iconify/react'
import React from 'react'
import restaurarEquipo from "../../../../Assets/Recursos/restaurar_equipo_icono.svg"

function ExplicacionReiniciar({ enviarProblemaASoporteTecnico }) {

    return (
        <div>
            <div className="col-12 mx-auto text-center pb-3">
                <iframe width="100%" style={{ aspectRatio: "16 / 9" }} className="rounded" src={'https://www.youtube.com/embed/zhvDXzLPoMA?si=VDeauzNYrYNJUc08'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <ol>
                <h6 className='pb-2'>Reiniciar equipo</h6>
                <li>
                    <div className="d-flex mb-2">
                        <span style={{ fontSize: 13 }}>
                            Presiona el botón <strong>Menu</strong> del control remoto.
                        </span>
                        <div className="col-2 text-center">
                            <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                        </div>
                    </div>
                </li>

                <li>
                    <div className="mb-2 d-flex">
                        <div className="col-7">
                            <span style={{ fontSize: 13 }}>Presiona <strong>OK</strong> en la opción <strong>Restaurar equipo.</strong></span>
                        </div>
                        <div className="text-end col-5">
                            <img src={restaurarEquipo} width={100} />
                        </div>
                    </div>
                </li>

                <li>
                    <div className="mb-2">
                        <div>
                            <span style={{ fontSize: 13 }}>Cuando veas el mensaje <strong>¿Eliminar todos los archivos durante la restauración?</strong>, selecciona <strong>SI</strong>.</span>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="mb-2">
                        <div>
                            <span style={{ fontSize: 13 }}>Luego de que se reinicie el reproductor, <strong>aguarda unos minutos</strong> para que vuelva a descargar el contenido.</span>
                        </div>
                    </div>
                </li>
            </ol>

            <p className='text-center pointer' style={{ fontStyle: 'italic', color: 'blue', fontSize: 15, textDecoration: 'underline' }} onClick={enviarProblemaASoporteTecnico}>
                ¿Todavía no pudiste solucionarlo?
            </p>


        </div>
    )
}

export default ExplicacionReiniciar