import React from 'react'
import img_prueba from "../../../../Assets/Header_AySF.png"
import { useNavigate } from 'react-router-dom'

function CadaSolucion2({ solucion, clave }) {

    const navigate = useNavigate()

    return (

        <>
            {
                window.innerWidth < 1000 &&
                <div style={{minHeight: '200px'}} onClick={() => navigate(`/${solucion.navegacion}`)} 
                className='pointer bg-light rounded text-center m-1 p-1 col-5'>
                    <img src={solucion.img} width={'100%'} className='rounded' />
                    <div className='text-start mx-2'>
                        <p style={{ fontSize: 12, marginBottom: 0 }}><strong>{solucion.titulo}</strong></p>
                        <p className='text-start' style={{ fontSize: 11 }}>{solucion.hint}</p>
                    </div>
                </div>
            }

            {
                window.innerWidth >= 1000 &&
                <div onClick={() => navigate(`/${solucion.navegacion}`)}
                    className='pointer bg-light rounded text-center m-2 col-3' >
                    <img src={solucion.img} width={'100%'} className='rounded' />
                    <div className='text-start mx-2'>
                        <p style={{ fontSize: 18, marginBottom: 0 }}><strong>{solucion.titulo}</strong></p>
                        <p className='text-start' style={{ fontSize: 13 }}>{solucion.hint}</p>
                    </div>
                </div>
            }

        </>


    )
}

export default CadaSolucion2