import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatearFechaLogFecha, verificarLogFechaConexion } from '../../../../Utils/Others';
import { Icon } from '@iconify/react';
import bicon from "../../../../Assets/bicon.svg"
import sinConexion from "../../../../Assets/SinConexion_gray_2.png"
import { useNavigate } from 'react-router-dom';
import ExplicacionReiniciar from './ExplicacionReiniciar';
import ExplicacionConexion from '../../../../Components/ExplicacionConexion';

function ClientInfoModal({ show, handleClose, fullClient, selectedDevice, devicesList, codigos }) {

    const navigate = useNavigate()

    const hablarConAdministracionPorPago = () => {
        window.open(`https://api.whatsapp.com/send?phone=+5493515936805&text=${encodeURIComponent(`Hola, escribo desde ${fullClient?.razon}, mis equipos muestran un mensaje de Configuración Bloqueda.`)}`);
    }

    const enviarProblemaASoporteTecnico = (device) => {
        window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Te escribo desde ${fullClient?.razon}. Mi Equipo MAC ${device?.macs} - PLANTILLA: ${device?.plantilla.toUpperCase()}, figura como conectado y tiene el siguiente estado: ${device?.log_descripcion}. ¿Podrían ayudarme?`)}`);
    }

    const enviarEstadoDesconocido = (device) => {
        window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Te escribo desde ${fullClient?.razon}. Mi Equipo MAC ${device?.macs} - Plantilla ${device?.plantilla.toUpperCase()}, tiene un estado desconocido: ${device?.log_tipo} - ${device?.log_descripcion}`)}`);
    }


    const handleEstadoEquipoPrincipal = (equipo) => {
        if (equipo?.log_tipo == 'peligro' && equipo?.log_descripcion == 'Configuración bloqueada por deuda') {
            return <div className='my-2'>
                <Button onClick={hablarConAdministracionPorPago} size='sm' variant='outline-light'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <Icon className='me-1' icon={'ic:round-whatsapp'} width={30} />
                        </div>
                        <div>Hablar con administración</div>
                    </div>
                </Button>
            </div>
        } else if (equipo?.log_tipo == 'error') {
            return <div className='shadow p-3 rounded col-12 mx-auto text-center'>
                <div>
                    <Icon color='salmon' width={40} icon={'ic:outline-error'} />
                    <p style={{ fontSize: 14 }}><strong>Tu equipo tiene un error</strong></p>
                </div>
                <div className='text-start mt-3'>
                    <ExplicacionReiniciar enviarProblemaASoporteTecnico={() => enviarProblemaASoporteTecnico(equipo)} />
                </div>
            </div>
        } else if (equipo?.log_tipo != 'error' && equipo?.log_tipo != 'peligro' && verificarLogFechaConexion(equipo?.log_fecha)) {
            return <>
                <div className='text-center'>
                    <img src={sinConexion} width={55} />

                    <span className='px-3 text-light' style={{ fontSize: 14, backgroundColor: '#D70926', borderRadius: 44, padding: 6 }}>Sin conexión</span> <br />

                    <div>
                        <p style={{ fontSize: 12 }}>Tu equipo está desconectado. <br /> Última actualización: <strong>{formatearFechaLogFecha(equipo?.log_fecha)} </strong></p>
                    </div>
                </div>
                <ExplicacionConexion handleEnviarInfoASoporte={() => enviarProblemaASoporteTecnico(equipo)} />
            </>
        } else if (equipo?.log_tipo != 'error' && equipo?.log_tipo != 'peligro' && !verificarLogFechaConexion(equipo?.log_fecha)) {
            return <>
                <div className='d-flex justify-content-center align-items-center'>
                    <Icon className='me-2' icon={'material-symbols:wifi-rounded'} width={45} />
                    <span className='px-3 py-1 text-light' style={{ fontSize: 14, backgroundColor: '#0DA500', borderRadius: 44 }}>Conectado</span>
                </div>

                <div onClick={() => enviarProblemaASoporteTecnico(equipo)} className="p-2 mt-3 col-8 mx-auto rounded pointer" style={{ backgroundColor: '#2563eb' }}>
                    <div className="d-flex align-items-center justify-content-center">
                        <div>
                            <Icon className="me-2" width={30} icon={'ic:baseline-whatsapp'} color="whitesmoke" />
                        </div>
                        <div>
                            <span className="text-light">Soporte técnico</span>
                        </div>
                    </div>
                </div>
            </>
        } else {
            return <>
                <div>
                    <p style={{ fontSize: 12 }}>No podemos identificar una solución adecuada para tu equipo. Por favor, conctactate con Soporte Técnico. </p>
                </div>

                <div onClick={() => enviarEstadoDesconocido(equipo)} className="p-2 mt-3 col-8 mx-auto rounded pointer" style={{ backgroundColor: '#2563eb' }}>
                    <div className="d-flex align-items-center justify-content-center">
                        <div>
                            <Icon className="me-2" width={30} icon={'ic:baseline-whatsapp'} color="whitesmoke" />
                        </div>
                        <div>
                            <span className="text-light">Soporte técnico</span>
                        </div>
                    </div>
                </div>
            </>
        }
    }

    const handleEstadoEquipoSecundarios = (equipo) => {
        if (equipo?.log_tipo == 'peligro' && equipo?.log_descripcion == 'Configuración bloqueada por deuda') {
            return <div className='my-2'>
                <Button onClick={hablarConAdministracionPorPago} size='sm' variant='outline-light'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <Icon className='me-1' icon={'ic:round-whatsapp'} width={30} />
                        </div>
                        <div>Hablar con administración</div>
                    </div>
                </Button>
            </div>
        } else if (equipo?.log_tipo == 'error') {
            return <div className='shadow p-3 rounded col-12 mx-auto text-center'>
                <div>
                    <Icon color='salmon' width={40} icon={'ic:outline-error'} />
                    <p style={{ fontSize: 14 }}><strong>Tu equipo tiene un error</strong></p>
                </div>
                <div className='text-start mt-3'>
                    <ExplicacionReiniciar enviarProblemaASoporteTecnico={() => enviarProblemaASoporteTecnico(equipo)} />
                </div>
            </div>
        } else if (equipo?.log_tipo != 'error' && equipo?.log_tipo != 'peligro' && verificarLogFechaConexion(equipo?.log_fecha)) {
            return <>
                <div className='text-center mt-2'>
                    <img src={sinConexion} width={58} />

                    <span className='px-3 text-light' style={{ fontSize: 14, backgroundColor: 'gray', borderRadius: 44, padding: 6 }}>Sin conexión</span> <br />

                    <div>
                        <p style={{ fontSize: 12 }}>Tu equipo está desconectado. <br /> Última actualización: <strong>{formatearFechaLogFecha(equipo?.log_fecha)} </strong></p>
                    </div>
                    <Button onClick={() => navigate("/soluciones/1")} size='sm' variant='outline-dark'>Tutorial Conexión</Button>
                </div>
            </>
        } else if (equipo?.log_tipo != 'error' && equipo?.log_tipo != 'peligro' && !verificarLogFechaConexion(equipo?.log_fecha)) {
            return <>
                <div className='d-flex justify-content-center align-items-center'>
                    <Icon className='me-2' icon={'material-symbols:wifi-rounded'} width={35} />
                    <span className='px-3 py-1 text-light' style={{ fontSize: 13, backgroundColor: '#0DA500', borderRadius: 44 }}>Conectado</span>
                </div>

                <div onClick={() => enviarProblemaASoporteTecnico(equipo)} className="p-2 mt-3 col-8 mx-auto rounded pointer" style={{ backgroundColor: '#2563eb' }}>
                    <div className="d-flex align-items-center justify-content-center">
                        <div>
                            <Icon className="me-2" width={20} icon={'ic:baseline-whatsapp'} color="whitesmoke" />
                        </div>
                        <div>
                            <span style={{ fontSize: 14 }} className="text-light">Soporte técnico</span>
                        </div>
                    </div>
                </div>
            </>
        } else {
            return <>
                <div>
                    <p style={{ fontSize: 12 }}>No podemos identificar una solución adecuada para tu equipo. Por favor, conctactate con Soporte Técnico. </p>
                </div>

                <div onClick={() => enviarEstadoDesconocido(equipo)} className="p-2 mt-3 col-8 mx-auto rounded pointer" style={{ backgroundColor: '#2563eb' }}>
                    <div className="d-flex align-items-center justify-content-center">
                        <div>
                            <Icon className="me-2" width={30} icon={'ic:baseline-whatsapp'} color="whitesmoke" />
                        </div>
                        <div>
                            <span className="text-light">Soporte técnico</span>
                        </div>
                    </div>
                </div>
            </>
        }
    }

    return (

        <Modal className='pt-5' show={show} onHide={handleClose}>

            <Modal.Header>
                <Modal.Title>
                    <div style={{ marginBottom: -10 }}>
                        <span>Hola, <strong>{codigos?.razon.toUpperCase()}</strong></span>
                    </div>
                    <div>
                        <span style={{ fontSize: 14 }}>Estado de equipo actual</span>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <div className="d-flex align-items-center col-10 ms-3 mt-2">
                <img className='p-2' style={{ backgroundColor: '#DA3E00', zIndex: 2, width: 50, height: 50, borderRadius: 50 }} src={bicon} />

                <div style={{ backgroundColor: '#DA3E00', height: 35, marginLeft: -20, zIndex: 1, borderRadius: 44 }} className="d-flex align-items-center col-12">
                    <span className="px-4 text-light" style={{ fontSize: 14 }}>{selectedDevice?.plantilla.toUpperCase()}</span>
                </div>
            </div>

            <Modal.Body>{handleEstadoEquipoPrincipal(selectedDevice)}</Modal.Body>

            <Modal.Body className='mt-2'>
                {
                    <div className='bg-gray-1 p-2 rounded'>
                        <span className='ms-2'><strong>Estado de otros equipos</strong></span>
                        {
                            devicesList?.map((device, index) => (
                                <div key={index} className='text-start rounded mb-2 p-2'>

                                    <span style={{ fontSize: 13 }}>{device?.plantilla.toUpperCase()} - {device?.posicion}</span>

                                    {handleEstadoEquipoSecundarios(device)}

                                </div>
                            ))
                        }
                    </div>
                }
            </Modal.Body>

            <Modal.Footer>
                <Icon className='pointer' onClick={handleClose} icon={'solar:close-circle-outline'} width={25} />
            </Modal.Footer>
        </Modal>
    )
}

export default ClientInfoModal