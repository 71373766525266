import React, { useState } from 'react'
import { soluciones_rapidas, soluciones_rapidas_2 } from "../../../Utils/Others"
import { Button, Form } from "react-bootstrap"
import bannerHeader from "../../../Assets/Header_AzulF.png"
import CadaSolucion from "./Components/CadaSolucion"
import { useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import ModalDescripcionProblema from "./Components/ModalDescripcionProblema"
import gif_mac from "../../../Assets/bar-code-mac.png"
import CadaSolucion2 from "./Components/CadaSolucion2"

function Home() {

    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [showModalProblema, setShowModalProblema] = useState(false)
    const handleCloseModalProblema = () => setShowModalProblema(false)
    const [descripcion, setDescripcion] = useState('')
    const [loading, setLoading] = useState(false)
    const [nombreEmpresa, setNombreEmpresa] = useState('')

    const handleHablaConNosotrosWap = () => setShowModalProblema(true)

    const handleIrAWhatsApp = () => {
        setLoading(true)
        window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Escribo desde ${nombreEmpresa} y necesito una solución para: ${descripcion}`)}`);
        setTimeout(() => {
            setShowModalProblema(false)
            setLoading(false)
        }, 500);
    }


    const navigateToAdvanced = () => {
        navigate("/validacioncliente")
    }

    return (
        <>

            <div className="text-center">
                <img className='mb-2' src={bannerHeader} width={window.innerWidth < 1000 ? '100%' : '30%'} />
            </div>

            <div>
                {/* Este te tiene que llevar a poner el código MAC o el CUIL/CUIT. */}
                <Button onClick={navigateToAdvanced} className="bg-orange p-2 text-light shadow rounded" style={{ fontSize: 14, position: 'fixed', bottom: 12, right: 12, border: '1px solid white' }} width={40}>
                    ✨ Consulta avanzada
                </Button>
            </div>


            <div className={`${window.innerWidth < 1000 ? 'col-11' : 'col-5'} mx-auto text-center`}>
                <Form.Control onChange={(e) => setSearch(e.target.value)} style={{ fontSize: 16 }} type="text" placeholder="Buscar soluciones..." />
            </div>

            <div className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                {
                    soluciones_rapidas_2
                        .filter((solucion) => {
                            const normalizedTags = solucion.tags.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                            const normalizedSearch = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                            return normalizedTags.includes(normalizedSearch);
                        })
                        .map((solucion, index) => (
                            <CadaSolucion2 clave={index} key={index} solucion={solucion} />
                        ))
                }
            </div>

            <div className={`${window.innerWidth < 1000 ? 'col-12' : 'col-4'} mx-auto`} style={{ backgroundColor: '#051131', borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                <div className="text-center p-5">
                    <div className="pb-1">
                        <h4 className="text-light">¿Necesitás otra solución?</h4>
                    </div>

                    <div onClick={handleHablaConNosotrosWap} className="p-3 rounded pointer" style={{ backgroundColor: '#2563eb' }}>
                        <div className="d-flex align-items-center justify-content-center">
                            <div>
                                <Icon className="me-2" width={30} icon={'ic:baseline-whatsapp'} color="whitesmoke" />
                            </div>
                            <div>
                                <span className="text-light">Hablá con nosotros</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModalProblema && <ModalDescripcionProblema setNombreEmpresa={setNombreEmpresa} loading={loading} handleIrAWhatsApp={handleIrAWhatsApp} descripcion={descripcion} setDescripcion={setDescripcion} showModalProblema={showModalProblema} handleCloseModalProblema={handleCloseModalProblema} />}

        </>
    )
}

export default Home