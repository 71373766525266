import { Button } from "react-bootstrap"
import PageTitle from "../../../../Components/PageTitle"
import "./QuickSolutionDetail.css"
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { soluciones_rapidas } from "../../../../Utils/Others"
import header_azul from "../../../../Assets/Header_AzulF.png"
import { Icon } from "@iconify/react"
import logo_azul from "../../../../Assets/logo_azul.svg"
import MenuNav from "../../../../Components/MenuNav"
import TituloMasLogo from "../../../../Components/TituloMasLogo"

function QuickSolutionDetail() {

    const { id } = useParams()
    const [solucion, setSolucion] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        setSolucion(soluciones_rapidas.find((solucion) => solucion.id == id))
    }, [id])

    return (
        <div className="text-light" style={{ backgroundColor: '#ededed' }}>
            <div className="text-center">
                <img src={header_azul} width={window.innerWidth < 1000 ? '100%' : '30%'} />
            </div>

            <div className={`${window.innerWidth < 1000 ? 'col-11' : 'col-4'} mt-4 mx-auto p-3`} style={{ backgroundColor: 'white', borderTopLeftRadius: 30, borderTopRightRadius: 30 }} >

                <TituloMasLogo solucion={solucion.titulo} />

                <div className="col-11 mx-auto mt-2 text-dark">
                    <p style={{ textAlign: 'justify', fontSize: 14 }}>{solucion.descripcion}</p>
                </div>

                {
                    solucion.video_tutorial &&
                    <div className="col-12 mx-auto text-center pb-3">
                        <iframe width="100%" style={{ aspectRatio: "16 / 9" }} className="rounded" src={solucion.video_tutorial} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                }


                <div style={{ height: 1 }} className="bg-secondary mb-3"></div>

                <div className="text-dark">
                    <span>Instrucciones</span>
                    <ol>
                        {solucion?.pasos?.map((paso, index) => (
                            <li key={index} style={{ fontSize: 13 }}>
                                <span>{paso}</span>
                            </li>
                        ))}
                    </ol>
                    <div className="text-center">
                        {
                            solucion?.opciones_continuacion?.map((opcion) => (
                                <p style={{ fontSize: 12, color: '#081A4C', fontStyle: 'italic', textDecoration: 'underline' }}>{opcion}</p>
                            ))
                        }
                    </div>
                </div>
            </div>

            <MenuNav />
        </div>
    )
}

export default QuickSolutionDetail