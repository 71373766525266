import { Icon } from '@iconify/react';
import { Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalDescripcionProblema({ showModalProblema, setNombreEmpresa, handleCloseModalProblema, descripcion, setDescripcion, handleIrAWhatsApp, loading }) {

    return (
        <div>
            <Modal centered show={showModalProblema} onHide={handleCloseModalProblema}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex align-items-center'>
                        <div>
                            <Icon color='#da3e00' className='me-1' icon={'ic:baseline-settings-suggest'} width={35} />
                        </div>
                        <div>
                            <span style={{ fontSize: 15 }}>Descripción de inconveniente</span>
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='text-start'>
                            <p style={{ fontSize: 13 }}>¿Cómo es el nombre de tu razón social, empresa o punto de venta?</p>
                        </div>
                        <Form.Control type='text' placeholder='Empresa/Razón social/Punto de venta' onChange={(e) => setNombreEmpresa(e.target.value)} />
                    </div>
                    <div className='mt-2'>
                        <div className='text-start'>
                            <p style={{ fontSize: 13 }}>Si podés, explicanos qué necesitas para ayudarnos a solucionarlo mucho más rápido.</p>
                        </div>
                        <Form.Control type='text' as={'textarea'} placeholder='Escribí acá...' onChange={(e) => setDescripcion(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {
                        !loading
                            ?
                            <Button onClick={handleIrAWhatsApp} style={{ backgroundColor: '#da3e00', border: 'none', borderRadius: 30, fontSize: 14 }}>
                                Ir a WhatsApp de Soporte Técnico
                            </Button>
                            :
                            <div className="mx-auto mt-2">
                                <Spinner style={{ color: 'orangered' }} animation="grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDescripcionProblema