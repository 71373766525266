import "./QuickSolutions.css"
import React from 'react'

function QuickSolutions() {
    return (
        <div>
            <h2>ESTE ES EL MAIN DE SOLUCIONES RÁPIDAS, ACÁ ESTARÁN LISTADAS TODAS LAS SOLUCIONES CON BUSCADORES</h2>
        </div>
    )
}

export default QuickSolutions