import React from 'react'
import { Button } from 'react-bootstrap'

function Pruebas() {


    const enviarMensaje = async () => {
        await fetch('https://graph.facebook.com/v18.0/257972117398980/messages', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer EAAPQULbbqRgBOz62ZAE4tZCfM42CP66BD9Mf4DdFMRc4QEFn4quvU0ytXK1PLnCBL1aTmqI2s8qtu22q7jdaP0A420GR6kkDxxWGtOG7o2ZAci8S1XMXJlVDAj6vHvvXf4rkxbZBE3XTCORYcZAgbsBTPNvZCEPL7c7ZBt4uQRjThCL0WUFqUMfW9ZChRZBMPejzrHSZBfcx4iCY115V5wWez0smWOnuOed5Ti',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                messaging_product: "whatsapp",
                "recipient_type": "individual",
                to: "543512444270",
                type: "text",
                "text": {
                    "preview_url": false,
                    "body": "Mensaje de prueba - texto"
                }
            })
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch((error) => {
                alert('Error:', error);
            });
    }

    return (
        <div>
            <Button onClick={() => enviarMensaje()}>Enviar mensaje</Button>
        </div>
    )
}

export default Pruebas