import { Icon } from "@iconify/react";
import cerrarAplicacion from "../Assets/Recursos/Soluciones_cerrar_aplicacion.svg"
import btnBanner from "../Assets/Recursos/Soluciones_control_1.svg"
import restaurarEquipo from "../Assets/Recursos/restaurar_equipo_icono.svg"
import restaurarEquipo2 from "../Assets/Recursos/Soluciones_restaurar_equipo-07.svg"
import rotarPantalla2 from "../Assets/Recursos/Soluciones_rotar pantalla-09.svg"
import rotarPantalla from "../Assets/Recursos/Soluciones_rotar_pantalla-08.svg"
import valoresFabrica from "../Assets/Recursos/Soluciones_restauracion_fabrica.svg"
import onOff from "../Assets/Recursos/encendido-apagado.png"
import ethernet from "../Assets/Recursos/ethernet.png"
import tv_banner from "../Assets/Recursos/TV_banner_TELE.svg"
import icono_restaurar from "../Assets/Recursos/icono_restaurar_fabrica.svg"
import perilla_switch from "../Assets/Perillita.png"

// SOLUCIONES
import hdmi_sin_senal from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-01.png"
import solo_logo from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-02.png"
import equipo_sin_config from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-03.png"
import no_hay_redes from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-04.png"
import anda_lento from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-11.png"
import distorsionado from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-12.png"
import todo_negro from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-07.png"
import dada_vuelta from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-06.png"
import franjas_verdes from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-05.png"
import proveedor from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-16.png"
import aplicada from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-15.png"
import sin_configuracion from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-13.png"
import verificacion_inicial from "../Assets/Portadas Soluciones/Imagenes_BannerSluciones-17.png"
import conexion_red from "../Assets/Portadas Soluciones/Gif_Conexion.gif"
import portal from "../Assets/Portadas Soluciones/Gif_PortaldeNoticias.gif"
import desactualizados from "../Assets/Portadas Soluciones/BannerSoluciones-Portadas--20.png"
import memoria from "../Assets/Portadas Soluciones/BannerSoluciones-Portadas--18.png"

export const problemCategories = ['Internet', 'Reproducción de contenidos', 'Contenido erróneo', 'Necesidad de cambio'
]

const usuarios_sistema_banner = [
    {
        nombre: 'Juan Pérez',
        empresa: 'ABC Company',
        cuil: '20-12345678-9',
        macAddress: ['A1B2C3', 'D4E5F6', 'G7H8I9'],
    },
    {
        nombre: 'María González',
        empresa: 'XYZ Corporation',
        cuil: '27-98765432-1',
        macAddress: ['J1K2L3', 'M4N5O6'],
    },
    {
        nombre: 'Carlos Rodríguez',
        empresa: 'Costumbres Argentinas',
        cuil: '30-70796596-3',
        macAddress: ['P7Q8R9', 'S1T2U3', 'V4W5X6'],
    },
    {
        nombre: 'Laura Smith',
        empresa: 'GHI Enterprises',
        cuil: '30-23456789-6',
        macAddress: ['Y7Z8A1', 'B2C3D4'],
    },
    {
        nombre: 'Pedro Martínez',
        empresa: 'LMN Ltd.',
        cuil: '24-34567890-2',
        macAddress: ['E5F6G7', 'H8I9J1', 'K2L3M4'],
    },
];

const terceros_dolibarr = [
    {
        "entity": "1",
        "name": "BANNER DIRECTOR S.A.",
        "name_alias": "BANNER DIRECTOR ARGENTINA",
        "address": "Caseros 980 1 A",
        "zip": "5000",
        "town": "Córdoba",
        "status": "1",
        "state_id": "883",
        "state_code": "2315",
        "state": "Córdoba",
        "phone": "5897000",
        "fax": null,
        "email": null,
        "socialnetworks": [],
        "url": null,
        "barcode": null,
        "idprof1": "30-71478755-8",
        "idprof2": "EXENTO",
        "idprof3": "",
        "idprof4": "",
        "idprof5": "",
        "idprof6": "",
        "tva_assuj": "1",
        "tva_intra": "Responsable Inscript",
        "localtax1_assuj": null,
        "localtax1_value": "0.000",
        "localtax2_assuj": null,
        "localtax2_value": "0.000",
        "managers": null,
        "capital": null,
        "typent_id": "8",
        "typent_code": "TE_PRIVATE",
        "effectif": "6 - 10",
        "effectif_id": "2",
        "forme_juridique_code": "2311",
        "forme_juridique": "Sociedad Anónima",
        "remise_percent": 0,
        "remise_supplier_percent": "0",
        "mode_reglement_supplier_id": null,
        "cond_reglement_supplier_id": null,
        "transport_mode_supplier_id": null,
        "fk_prospectlevel": "",
        "date_modification": 1621260156,
        "user_modification": "1",
        "date_creation": 1621260156,
        "user_creation": "1",
        "client": "0",
        "prospect": 0,
        "fournisseur": "0",
        "code_client": null,
        "code_fournisseur": null,
        "code_compta": null,
        "code_compta_client": null,
        "code_compta_fournisseur": null,
        "note_private": null,
        "note_public": null,
        "stcomm_id": "0",
        "stcomm_picto": null,
        "status_prospect_label": "Never contacted",
        "price_level": null,
        "outstanding_limit": null,
        "order_min_amount": null,
        "supplier_order_min_amount": null,
        "parent": null,
        "default_lang": null,
        "ref": "1",
        "ref_ext": null,
        "import_key": null,
        "webservices_url": null,
        "webservices_key": null,
        "logo": null,
        "logo_small": null,
        "logo_mini": null,
        "logo_squarred": null,
        "logo_squarred_small": null,
        "logo_squarred_mini": null,
        "fk_multicurrency": "0",
        "multicurrency_code": "",
        "bank_account": null,
        "id": "1",
        "array_options": [],
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "fk_project": null,
        "contact": null,
        "contact_id": null,
        "user": null,
        "origin": null,
        "origin_id": null,
        "statut": null,
        "country": "Argentina",
        "country_id": "23",
        "country_code": "AR",
        "region_id": null,
        "barcode_type": null,
        "barcode_type_code": null,
        "barcode_type_label": null,
        "barcode_type_coder": null,
        "mode_reglement_id": null,
        "cond_reglement_id": null,
        "demand_reason_id": null,
        "transport_mode_id": null,
        "cond_reglement": null,
        "shipping_method_id": null,
        "model_pdf": "",
        "last_main_doc": null,
        "fk_bank": null,
        "fk_account": "0",
        "openid": null,
        "lastname": null,
        "firstname": null,
        "civility_id": null,
        "date_validation": null,
        "specimen": 0,
        "alreadypaid": null,
        "fk_incoterms": "0",
        "label_incoterms": null,
        "location_incoterms": null,
        "modelpdf": ""
    },
    {
        "entity": "1",
        "name": "Paulo Matías Ferrer",
        "name_alias": "REPLAY PRODUCTORA",
        "address": "Av 9 de Julio 435",
        "zip": "4440",
        "town": "Metán",
        "status": "1",
        "state_id": "873",
        "state_code": "2305",
        "state": "Salta",
        "phone": "387-5646393",
        "fax": null,
        "email": "productora.replay@gmail.com",
        "socialnetworks": [],
        "url": null,
        "barcode": null,
        "idprof1": "20335821921",
        "idprof2": "MONOTRIBUTISTA",
        "idprof3": "",
        "idprof4": "",
        "idprof5": "",
        "idprof6": "",
        "tva_assuj": "0",
        "tva_intra": "",
        "localtax1_assuj": null,
        "localtax1_value": "0.000",
        "localtax2_assuj": null,
        "localtax2_value": "0.000",
        "managers": null,
        "capital": null,
        "typent_id": "8",
        "typent_code": "TE_PRIVATE",
        "effectif": "",
        "effectif_id": null,
        "forme_juridique_code": "2301",
        "forme_juridique": "Monotributista",
        "remise_percent": 0,
        "remise_supplier_percent": "0",
        "mode_reglement_supplier_id": null,
        "cond_reglement_supplier_id": null,
        "transport_mode_supplier_id": null,
        "fk_prospectlevel": "",
        "date_modification": 1661364738,
        "user_modification": "1",
        "date_creation": 1621321067,
        "user_creation": "6",
        "client": "1",
        "prospect": 0,
        "fournisseur": "0",
        "code_client": "CU2105-00001",
        "code_fournisseur": null,
        "code_compta": null,
        "code_compta_client": null,
        "code_compta_fournisseur": null,
        "note_private": null,
        "note_public": null,
        "stcomm_id": "0",
        "stcomm_picto": null,
        "status_prospect_label": "Never contacted",
        "price_level": null,
        "outstanding_limit": null,
        "order_min_amount": null,
        "supplier_order_min_amount": null,
        "parent": null,
        "default_lang": null,
        "ref": "2",
        "ref_ext": null,
        "import_key": null,
        "webservices_url": null,
        "webservices_key": null,
        "logo": null,
        "logo_small": null,
        "logo_mini": null,
        "logo_squarred": null,
        "logo_squarred_small": null,
        "logo_squarred_mini": null,
        "fk_multicurrency": "0",
        "multicurrency_code": "",
        "bank_account": null,
        "id": "2",
        "array_options": [],
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "fk_project": null,
        "contact": null,
        "contact_id": null,
        "user": null,
        "origin": null,
        "origin_id": null,
        "statut": null,
        "country": "Argentina",
        "country_id": "23",
        "country_code": "AR",
        "region_id": null,
        "barcode_type": null,
        "barcode_type_code": null,
        "barcode_type_label": null,
        "barcode_type_coder": null,
        "mode_reglement_id": null,
        "cond_reglement_id": null,
        "demand_reason_id": null,
        "transport_mode_id": null,
        "cond_reglement": null,
        "shipping_method_id": null,
        "model_pdf": "",
        "last_main_doc": null,
        "fk_bank": null,
        "fk_account": "0",
        "openid": null,
        "lastname": null,
        "firstname": null,
        "civility_id": null,
        "date_validation": null,
        "specimen": 0,
        "alreadypaid": null,
        "fk_incoterms": "0",
        "label_incoterms": null,
        "location_incoterms": null,
        "modelpdf": ""
    },
    {
        "entity": "1",
        "name": "Grido Helados",
        "name_alias": "Grido",
        "address": "",
        "zip": "5000",
        "town": "Córdoba",
        "status": "1",
        "state_id": "883",
        "state_code": "2315",
        "state": "Córdoba",
        "phone": null,
        "fax": null,
        "email": null,
        "socialnetworks": [],
        "url": null,
        "barcode": null,
        "idprof1": "",
        "idprof2": "",
        "idprof3": "",
        "idprof4": "",
        "idprof5": "",
        "idprof6": "",
        "tva_assuj": "1",
        "tva_intra": "",
        "localtax1_assuj": null,
        "localtax1_value": "0.000",
        "localtax2_assuj": null,
        "localtax2_value": "0.000",
        "managers": null,
        "capital": null,
        "typent_id": "0",
        "typent_code": "TE_UNKNOWN",
        "effectif": "",
        "effectif_id": null,
        "forme_juridique_code": null,
        "forme_juridique": "",
        "remise_percent": 0,
        "remise_supplier_percent": "0",
        "mode_reglement_supplier_id": null,
        "cond_reglement_supplier_id": null,
        "transport_mode_supplier_id": null,
        "fk_prospectlevel": "",
        "date_modification": 1663107531,
        "user_modification": "1",
        "date_creation": 1622023913,
        "user_creation": "8",
        "client": "3",
        "prospect": 0,
        "fournisseur": "0",
        "code_client": "CU2105-00002",
        "code_fournisseur": null,
        "code_compta": null,
        "code_compta_client": null,
        "code_compta_fournisseur": null,
        "note_private": null,
        "note_public": null,
        "stcomm_id": "0",
        "stcomm_picto": null,
        "status_prospect_label": "Never contacted",
        "price_level": null,
        "outstanding_limit": null,
        "order_min_amount": null,
        "supplier_order_min_amount": null,
        "parent": null,
        "default_lang": null,
        "ref": "3",
        "ref_ext": null,
        "import_key": null,
        "webservices_url": null,
        "webservices_key": null,
        "logo": null,
        "logo_small": null,
        "logo_mini": null,
        "logo_squarred": null,
        "logo_squarred_small": null,
        "logo_squarred_mini": null,
        "fk_multicurrency": "0",
        "multicurrency_code": "",
        "bank_account": null,
        "id": "3",
        "array_options": [],
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "fk_project": null,
        "contact": null,
        "contact_id": null,
        "user": null,
        "origin": null,
        "origin_id": null,
        "statut": null,
        "country": "Argentina",
        "country_id": "23",
        "country_code": "AR",
        "region_id": null,
        "barcode_type": null,
        "barcode_type_code": null,
        "barcode_type_label": null,
        "barcode_type_coder": null,
        "mode_reglement_id": null,
        "cond_reglement_id": null,
        "demand_reason_id": null,
        "transport_mode_id": null,
        "cond_reglement": null,
        "shipping_method_id": null,
        "model_pdf": "",
        "last_main_doc": null,
        "fk_bank": null,
        "fk_account": "0",
        "openid": null,
        "lastname": null,
        "firstname": null,
        "civility_id": null,
        "date_validation": null,
        "specimen": 0,
        "alreadypaid": null,
        "fk_incoterms": "0",
        "label_incoterms": null,
        "location_incoterms": null,
        "modelpdf": ""
    },
    {
        "entity": "1",
        "name": "Grupo Almar S.R.L",
        "name_alias": "COSTUMBRES ARGENTINAS",
        "address": "",
        "zip": "1611",
        "town": "Don Torcuato",
        "status": "1",
        "state_id": "884",
        "state_code": "2316",
        "state": "Buenos Aires",
        "phone": null,
        "fax": null,
        "email": null,
        "socialnetworks": [],
        "url": null,
        "barcode": null,
        "idprof1": "30707965963",
        "idprof2": "IVA INSCRIPTO",
        "idprof3": "",
        "idprof4": "",
        "idprof5": "",
        "idprof6": "",
        "tva_assuj": "1",
        "tva_intra": "",
        "localtax1_assuj": null,
        "localtax1_value": "0.000",
        "localtax2_assuj": null,
        "localtax2_value": "0.000",
        "managers": null,
        "capital": null,
        "typent_id": "0",
        "typent_code": "TE_UNKNOWN",
        "effectif": "",
        "effectif_id": null,
        "forme_juridique_code": null,
        "forme_juridique": "",
        "remise_percent": 0,
        "remise_supplier_percent": "0",
        "mode_reglement_supplier_id": null,
        "cond_reglement_supplier_id": null,
        "transport_mode_supplier_id": null,
        "fk_prospectlevel": "",
        "date_modification": 1661354305,
        "user_modification": "1",
        "date_creation": 1622025453,
        "user_creation": "8",
        "client": "1",
        "prospect": 0,
        "fournisseur": "0",
        "code_client": "CU2105-00003",
        "code_fournisseur": null,
        "code_compta": null,
        "code_compta_client": null,
        "code_compta_fournisseur": null,
        "note_private": null,
        "note_public": null,
        "stcomm_id": "0",
        "stcomm_picto": null,
        "status_prospect_label": "Never contacted",
        "price_level": null,
        "outstanding_limit": null,
        "order_min_amount": null,
        "supplier_order_min_amount": null,
        "parent": null,
        "default_lang": null,
        "ref": "4",
        "ref_ext": null,
        "import_key": null,
        "webservices_url": null,
        "webservices_key": null,
        "logo": null,
        "logo_small": null,
        "logo_mini": null,
        "logo_squarred": null,
        "logo_squarred_small": null,
        "logo_squarred_mini": null,
        "fk_multicurrency": "0",
        "multicurrency_code": "",
        "bank_account": null,
        "id": "4",
        "array_options": [],
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "fk_project": null,
        "contact": null,
        "contact_id": null,
        "user": null,
        "origin": null,
        "origin_id": null,
        "statut": null,
        "country": "Argentina",
        "country_id": "23",
        "country_code": "AR",
        "region_id": null,
        "barcode_type": null,
        "barcode_type_code": null,
        "barcode_type_label": null,
        "barcode_type_coder": null,
        "mode_reglement_id": null,
        "cond_reglement_id": null,
        "demand_reason_id": null,
        "transport_mode_id": null,
        "cond_reglement": null,
        "shipping_method_id": null,
        "model_pdf": "",
        "last_main_doc": null,
        "fk_bank": null,
        "fk_account": "0",
        "openid": null,
        "lastname": null,
        "firstname": null,
        "civility_id": null,
        "date_validation": null,
        "specimen": 0,
        "alreadypaid": null,
        "fk_incoterms": "0",
        "label_incoterms": null,
        "location_incoterms": null,
        "modelpdf": ""
    },
    {
        "entity": "1",
        "name": "CSMR AGRUPACION DE COLABORACION EMPRESARIA",
        "name_alias": "FARMACIA SELMA",
        "address": "Domicilio de Envío: Valentín Gdor Vergara 1947",
        "zip": "1638",
        "town": "Vicente Lopez",
        "status": "1",
        "state_id": "884",
        "state_code": "2316",
        "state": "Buenos Aires",
        "phone": null,
        "fax": null,
        "email": null,
        "socialnetworks": [],
        "url": null,
        "barcode": null,
        "idprof1": "30714623806",
        "idprof2": "IVA INSCRIPTO",
        "idprof3": "",
        "idprof4": "",
        "idprof5": "",
        "idprof6": "",
        "tva_assuj": "1",
        "tva_intra": "",
        "localtax1_assuj": null,
        "localtax1_value": "0.000",
        "localtax2_assuj": null,
        "localtax2_value": "0.000",
        "managers": null,
        "capital": null,
        "typent_id": "3",
        "typent_code": "TE_MEDIUM",
        "effectif": "",
        "effectif_id": null,
        "forme_juridique_code": null,
        "forme_juridique": "",
        "remise_percent": 0,
        "remise_supplier_percent": "0",
        "mode_reglement_supplier_id": null,
        "cond_reglement_supplier_id": null,
        "transport_mode_supplier_id": null,
        "fk_prospectlevel": "",
        "date_modification": 1661358837,
        "user_modification": "1",
        "date_creation": 1622097888,
        "user_creation": "6",
        "client": "1",
        "prospect": 0,
        "fournisseur": "0",
        "code_client": "CU2105-00004",
        "code_fournisseur": null,
        "code_compta": null,
        "code_compta_client": null,
        "code_compta_fournisseur": null,
        "note_private": null,
        "note_public": null,
        "stcomm_id": "0",
        "stcomm_picto": null,
        "status_prospect_label": "Never contacted",
        "price_level": null,
        "outstanding_limit": null,
        "order_min_amount": null,
        "supplier_order_min_amount": null,
        "parent": null,
        "default_lang": null,
        "ref": "5",
        "ref_ext": null,
        "import_key": null,
        "webservices_url": null,
        "webservices_key": null,
        "logo": null,
        "logo_small": null,
        "logo_mini": null,
        "logo_squarred": null,
        "logo_squarred_small": null,
        "logo_squarred_mini": null,
        "fk_multicurrency": "0",
        "multicurrency_code": "",
        "bank_account": null,
        "id": "5",
        "array_options": [],
        "array_languages": null,
        "linkedObjectsIds": null,
        "canvas": null,
        "fk_project": null,
        "contact": null,
        "contact_id": null,
        "user": null,
        "origin": null,
        "origin_id": null,
        "statut": null,
        "country": "Argentina",
        "country_id": "23",
        "country_code": "AR",
        "region_id": null,
        "barcode_type": null,
        "barcode_type_code": null,
        "barcode_type_label": null,
        "barcode_type_coder": null,
        "mode_reglement_id": null,
        "cond_reglement_id": null,
        "demand_reason_id": null,
        "transport_mode_id": null,
        "cond_reglement": null,
        "shipping_method_id": null,
        "model_pdf": "",
        "last_main_doc": null,
        "fk_bank": null,
        "fk_account": "0",
        "openid": null,
        "lastname": null,
        "firstname": null,
        "civility_id": null,
        "date_validation": null,
        "specimen": 0,
        "alreadypaid": null,
        "fk_incoterms": "0",
        "label_incoterms": null,
        "location_incoterms": null,
        "modelpdf": ""
    }
];

// export const obtenerTerceroPorMacAddress = (macAddress) => {
//     const usuarioValidadoBanner = usuarios_sistema_banner.find((usuario) => usuario.macAddress.includes(macAddress))

//     if (usuarioValidadoBanner) {
//         const usuarioDolibarr = terceros_dolibarr.find((usuarioDoli) => usuarioDoli.idprof1 == usuarioValidadoBanner.cuil)
//         return usuarioDolibarr.client
//     } else {
//         alert('No hay usuario')
//     }

// }

const url = 'https://bannerdirector.com/webservice/chatboot-utils/get-info-entidad-by-mac-or-codigo-interno'

export const validarIdentidad = async (field, value) => {

    const response = await fetch(url, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
            [field]: value && value.toLowerCase()
        }),
    })

    const data = await response.json()

    if (data?.razon) {
        return data
    }

}

export const obtenerEquipoPorCuil = async (cuil) => {

    try {
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({
                codigo_interno: cuil
            }),
        })

        const data = await response.json()
        console.log(data);
        return data

    } catch (error) {
        alert(error)
    }
}

export const obtenerEquipoPorCodigoMac = async (mac) => {

    try {
        const response = await fetch(url, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({
                mac: mac
            }),
        })

        const data = await response.json()
        console.log(data);
        return data

    } catch (error) {
        alert(error)
    }

}

export const grido = 'ac:d0:74:7a:7d:05'
export const demo = '80E4DA872ECF'
export const creamy = '80:e4:da:86:f2:43'
export const creamy2 = '80e4da86f411'

export const verificarLogFechaConexion = (log_fecha) => {
    let fechaLog = new Date(log_fecha);
    let ahora = new Date();
    let diferencia = ahora.getTime() - fechaLog.getTime();
    let horas = diferencia / (1000 * 60 * 60);
    if (horas > 1) {
        return true
    }
}

export function formatearFechaLogFecha(fechaString) {
    const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const fecha = new Date(fechaString);

    const diaSemana = dias[fecha.getDay()];
    const año = fecha.getFullYear();
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const hora = fecha.getHours();
    const minutos = fecha.getMinutes();

    return `${diaSemana}, ${dia}/${mes < 10 ? '0' + mes : mes}/${año} ${hora}:${minutos < 10 ? '0' + minutos : minutos}`;
}

// VIDEOS EXTERNOS YOUTUBE

export const videoTutorialConexionAInternet = 'https://www.youtube.com/watch?v=FIg-oqSnv40'

export const normalizeCategoryProblem = (categoryProblem) => {
    if (categoryProblem == 'Solicitud de asistencia técnica') {
        return 'Vemos que tienes problemas de tipo general, y que necesitas algún tipo de asistencia técnica.'
    } else if (categoryProblem == 'Actualización de firmware') {
        return 'Vemos que tienes inconvenientes con el firmware de tus equipos. Probablemente tengamos que actualizarlos.'
    } else {
        return categoryProblem
    }
}

export function eliminarDosPuntos(codigo) {
    return codigo.replace(/:/g, '');
}

export const soluciones_rapidas = [
    {
        titulo: 'Conectar equipos a internet - WIFI',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/1',
        items: [
            <span>
                ¿Tenés los precios desactualizados?
            </span>,
            <span>
                ¿Tu contenido no se actualiza?
            </span>,
            <span>
                '¿La pantalla está en negro, dice <strong>“Verif. inicial de medios a reproducir”?</strong> o está solamente el <strong>Logo de Banner Director?</strong>
            </span>,
            <span>
                ¿En la parte inferior dice <strong>"Sin conexión de red"</strong>?
            </span>
        ],
        id: '1',

        descripcion: 'La conexión de los equipos a internet es muy importante para que tus equipos funcionen correctamente.',

        video_tutorial: 'https://www.youtube.com/embed/7nJyJGTHVT4?si=ak1lA67FFwaeXLSK',

        pasos: [
            <div className="d-flex mb-3">
                <span>
                    Presiona el botón <strong>Menu</strong> del control remoto.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                </div>
            </div>
            ,

            <div className="mb-3 d-flex">
                <div className="col-7">
                    <span>Presiona <strong>OK</strong> en la opción <strong>Cerrar aplicación.</strong></span>
                </div>
                <div className="col-5 text-end">
                    <img src={cerrarAplicacion} width={100} />
                </div>
            </div>,

            <div className="d-flex mb-3 align-items-center">
                <span>
                    Cuando se cierre el reproductor, presiona  el <strong>botón de WIFI</strong> del control remoto.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'ic:round-wifi'} />
                </div>

            </div>,

            <div className="mb-3">
                <div>
                    <span>Busca tu red de wifi, presiona <strong>OK</strong> e ingresa la contraseña.</span>
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Si no figuran redes disponibles, prueba presionando el botón <strong>Switch de Encendido</strong> dos veces, para cambiar el <strong>Estado</strong>. Puedes hacerlo con el <strong>botón Mouse</strong> del control remoto.</span>
                </div>
                <div className="text-center">
                    <img src={perilla_switch} width={200} className="rounded" />
                </div>

            </div>,

            <div className="mb-3 d-flex">
                <div>
                    <span>Si tu reproductor todavía no se reinició, presiona el <strong>botón amarillo Banner Director</strong>.</span>
                </div>
                <div className="text-start">
                    <img src={btnBanner} width={150} />
                </div>
            </div>,

        ],

        opciones_continuacion: [
            <div>
                <a href="http://soluciones.bannerdirector.com/soluciones/3" target="_blank">¿En la lista de redes no figura ninguna?</a>
            </div>,
            <div>
                <a href="http://soluciones.bannerdirector.com/soluciones/5" target="_blank">¿Seleccionaste una red y tu equipo todavía no se conecta?</a>
            </div>,

        ]
    },

    {
        titulo: 'Reiniciar la aplicación',

        tags: 'memoria, falta de memoria, desactualizados, precios no actualizados, error, proveedor de configuración, intento actualizar pero no se modifica',
        navegacion: 'soluciones/5',

        items: [
            <span>¿Tus precios no se actualizan aunque tu equipo esté conectado a la red?</span>,
            <span>¿Ves un mensaje <strong>“Ocurrió un error al utilizar el proveedor de configuración”?</strong></span>,
            <span>¿El gráfico de memoria figura <strong>todo naranja?</strong></span>,
            <span>Actualizaste el equipo, ¿hay un mensaje de <strong>Configuración aplicada</strong> y todavía no se actualizó?</span>
        ],
        id: '5',

        descripcion: 'Si tu equipo está conectado a internet pero no funciona correctamente (se tilda, anda lento o no se actualiza), probablemente debas reiniciarlo.',

        video_tutorial: "https://www.youtube.com/embed/zhvDXzLPoMA?si=VDeauzNYrYNJUc08",


        pasos: [
            <div className="d-flex mb-2">
                <span>
                    Presiona el botón <strong>Menu</strong> del control remoto.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                </div>
            </div>,

            <div className="mb-2 d-flex">
                <div className="col-7">
                    <span>Presiona <strong>OK</strong> en la opción <strong>Restaurar equipo.</strong></span>
                </div>
                <div className="text-end col-5">
                    <img src={restaurarEquipo} width={100} />
                </div>
            </div>,

            <div className="mb-2">
                <div>
                    <span>Cuando veas el mensaje <strong>¿Eliminar todos los archivos durante la restauración?</strong>, selecciona <strong>SI</strong>.</span>
                </div>
            </div>,

            <div className="mb-2">
                <div>
                    <span>Luego de que se reinicie el reproductor, <strong>aguarda unos minutos</strong> para que vuelva a descargar el contenido.</span>
                </div>
            </div>
        ],


        opciones_continuacion: [
            <div>
                <a href="http://soluciones.bannerdirector.com/soluciones/3" target="_blank">¿Se reinició el equipo, descargó archivos y todavía no funciona?</a>
            </div>
        ]
    },

    {
        titulo: 'Rotar o ajustar pantalla',

        tags: 'pantalla al revés, rotar pantalla, reves, elementos distorsionados, mal, mover, cambiar, orientación, orientacion, pantalla patas para arriba',

        navegacion: 'soluciones/2',

        items: [
            <span>¿Tu pantalla está dada vuelta o al revés?</span>,
            <span>¿Los elementos se ven distorsionados o no coinciden?</span>,
            <span>¿Te aparece <strong>una franja verde</strong> en los margenes de la pantalla?</span>
        ],

        id: '2',
        descripcion: 'Si tu pantalla está dada vuelta, o la orientación del contenido es incorrecta, rotar o ajustar la pantalla desde el control remoto es la solución indicada.',

        video_tutorial: 'https://www.youtube.com/embed/uP30Y3q52QU?si=oKsULg_asdkHtJzi',


        pasos: [

            <div className="d-flex mb-2">
                <span>
                    Presiona el botón <strong>Menu</strong> del control remoto.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                </div>
            </div>,

            <div className="mb-3 d-flex">
                <div className="col-7">
                    <span>Presiona <strong>OK</strong> en la opción <strong>Cerrar aplicación.</strong></span>
                </div>
                <div className="col-5 text-end">
                    <img src={cerrarAplicacion} width={100} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Cuando se cierre el reproductor, presiona en el botón <strong>Rotar escritorio/pantalla</strong>.</span>
                </div>
                <div className="text-end">
                    <img className="rounded" src={rotarPantalla} width={150} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>En función de cómo esté acomodado tu televisor, selecciona los <strong>grados necesarios</strong> para lograr la orientación correcta. Podes ayuudarte con el <strong>Botón Mouse</strong> del control remoto. </span>
                </div>
                <div className="text-center">
                    <img className="rounded" src={rotarPantalla2} width={280} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Si tenes <strong>franjas verdes</strong>: <br />
                        Presiona <strong>Menú</strong> y luego <strong>Cerrar Aplicación</strong>. Luego presiona el botón <strong>Configuración ⚙</strong> en el control remoto, y dentro de la sección <strong>Dispositvo</strong>, presiona en <strong>Pantalla</strong> y luego en <strong>Screen Scale</strong>. Ajusta el tamaño con las flechas para <strong>quitar los bordes verdes.</strong> </span>
                </div>
                <div className="text-center mt-1">
                    <img className="rounded" src={tv_banner} width={200} />
                </div>
            </div>,

            <div className="mb-2">
                <div>
                    <span>Para finalizar, presiona el Botón Amarillo Banner Director del control remoto. </span>
                </div>
            </div>
        ]
    },

    {
        titulo: 'Restablecer a valores de fábrica',

        tags: 'redes wifi, no aparecen, conectar a wifi, lento, desactualizado, no se actualiza, portal de noticias, mensaje de "nose pudo aplicar la configuración"',

        navegacion: 'soluciones/3',

        items: [
            <span>¿Las redes de wifi <strong>no aparecen en pantalla?</strong></span>,
            <span>¿El equipo <strong>está lento o no se actualiza?</strong></span>,
            <span>¿El portal de noticias figura sin memoria?</span>
        ],

        id: '3',
        descripcion: 'Si tienes problemas de memoria o tu equipo no muestra las redes WIFI disponibles, es probable que necesites restablecer el Equipo Banner Director a valores de fábrica.',
        video_tutorial: 'https://www.youtube.com/embed/SMCCm816YbA?si=A1_YqlNN9kXcwBe0',

        pasos: [


            <div className="d-flex mb-3">
                <span>
                    Presiona el botón <strong>Menu</strong> del control remoto.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                </div>
            </div>,

            <div className="mb-3 d-flex">
                <div className="col-7">
                    <span>Presiona <strong>OK</strong> en la opción <strong>Cerrar aplicación.</strong></span>
                </div>
                <div className="col-5 text-end">
                    <img src={cerrarAplicacion} width={100} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Presiona <strong>OK</strong> en la opción <strong>Restauración de fábrica</strong>.</span>
                </div>
                <div className="mt-1 text-end">
                    <img className="rounded" src={icono_restaurar} width={150} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Cuando veas el mensaje <strong>'¿Está seguro que desea iniciar la restauración de fábrica del dispositivo? </strong>, presiona en <strong>SI</strong>.</span>
                </div>
                <div className="text-end mt-1">
                    <img className="rounded" src={valoresFabrica} width={180} />
                </div>
            </div>,

            <div className="mb-3">
                <div>
                    <span>Finalmente, cuando se reinicie el equipo, deberás volver a <a href="http://soluciones.bannerdirector.com/soluciones/1" target="_blank">conectarlo a internet.</a></span>
                </div>
            </div>
        ],



    },

    {
        titulo: 'Asignar modo “HDMI” correcto al TV',
        tags: 'hdmi, conexion, sin señal, pantalla negra, no se ve nada, hdmi correcto',
        navegacion: 'soluciones/4',
        items: [
            <span>¿Ves el mensaje <strong>"Sin Señal"</strong> en pantalla?</span>,
            <span>¿La pantalla está completamente negra?</span>,
            <span>¿El equipo está encendido pero no se ve nada?</span>
        ],
        id: '4',

        descripcion: 'Para que tu equipo funcione correctamente, es fundalmental que esté bien conectado a un televisor. Esto se hace a través del puerto HDMI.',
        video_tutorial: 'https://www.youtube.com/embed/W65x7SFOOwQ?si=CvQo-yAubZG99jQz',

        pasos: [

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Utiliza el control del televisor y busca la opción <strong>'Source', 'Entradas' o 'Input'.</strong>
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'ic:round-input'} />
                </div>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Chequear/validar en <strong>qué número de puerto HDMI</strong> del televisor está conectado el cable que va hacia el Equipo Banner Director.
                </span>
                <div className="col-2 text-center">
                    <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'ic:outline-settings-input-hdmi'} />
                </div>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Con el control del televisor, <strong>seleccionar la entrada (número)</strong> en la que está conectado el Equipo Banner Director.
                </span>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <div>
                    <span>
                        Si no funciona inmediatamente, <strong>reinicia el Equipo Banner Director</strong> con el botón switch del dispositivo o desconectalo y volvelo a conectar.
                    </span>
                </div>
                <div className="col-2 text-center shadow rounded">
                    <img src={onOff} width={42} className="p-1" />
                </div>
            </div>

        ]
    },

    {
        titulo: 'Conectar equipos a internet - Cable',
        tags: 'hdmi, conexión, conexion, sin señal, pantalla negra, no se ve nada, hdmi correcto',
        navegacion: 'soluciones/7',
        items: [
            <span>¿Conectas tus Equipos Banner Director por <strong>cable de red - Ethernet?</strong></span>,
            <span>¿No tenes conexión o tus equipos no se actualizan?</span>,
        ],
        id: '7',

        descripcion: 'Si tu conexión a internet es mediante cable de red, es probable que necesites chequear el estado de tu módem.',

        pasos: [

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Conecta y desconecta el cable de red - Ethernet del Equipo Banner Director.
                </span>
                <div className="col-2 text-center">
                    <img src={ethernet} width={40} />
                </div>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Si todavía no tienes conexión, reinicia el módem proveedor del servicio de internet.
                </span>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Si todavía no tienes conexión, chequea el buen estado del cable Ethernet.
                </span>
            </div>,

            <div className="d-flex mb-2 align-items-center">
                <span>
                    Para confirmar que todo esté en condiciones, conecta el mismo cable en otro dispositivo (Computadora) para identificar dónde está el problema.
                </span>
            </div>,
        ],

        // opciones_continuacion: [
        //     <div>
        //         <a href="https://google.com.ar" target="_blank">¿Necesitas descargar/actualizar contenido si o si? Usa los datos de tu celular.</a>
        //     </div>,
        //     ,
        // ]
    },

]

export const soluciones_rapidas_2 = [
    {
        titulo: 'Contenido desactualizado',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/1',
        hint: '¿Tenés los precios o algún otro contenido desactualizado?',
        tags: 'No se me actualiza la pantalla - No me aparece el contenido actualizado - me figura conectado pero no se actualiza',
        img: desactualizados
    },
    {
        titulo: 'Verificación inicial de medios a reproducir',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/1',
        hint: '¿Ves este texto hace demasiado tiempo?',
        img: verificacion_inicial,
        tags: 'la pantalla se queda cargando - la pantalla verifica los medios'
    },
    {
        titulo: 'Equipo sin configuración. Sin conexión de red',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/1',
        hint: '¿Ves este texto en pantalla?',
        img: sin_configuracion,
        tags: 'No se me actualiza la pantalla - no puedo conectar a internet - no me descarga el contenido'
    },
    {
        titulo: 'Ocurrió un error al utilizar el proveedor de configuración',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/5',
        hint: '¿Ves esta frase en tu televisor?',
        img: proveedor,
        tags: 'la pantalla banner me da error'
    },
    {
        titulo: 'Gráfico de memoria naranja',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/5',
        hint: '¿El gráfico está completamente naranja?',
        tags: 'mi banner no tiene memoria',
        img: memoria
    },
    {
        titulo: 'Configuración aplicada',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/5',
        hint: '¿Ya actualizaste tu equipo y ves este mensaje?',
        img: aplicada,
        tags: 'me dice confi - configuracion aplicada pero no se me actualiza o no me aparece el contenido'
    },
    {
        titulo: 'Pantalla al revés',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/2',
        hint: '¿Tu pantalla está dada vuelta o al revés?',
        img: dada_vuelta,
        tags: 'la imagen está dada vuelta - imagen al revés'
    },
    {
        titulo: 'Elementos mal posicionados de lugar',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/2',
        hint: '¿Los elementos se ven distorsionados o no coinciden?',
        img: distorsionado,
        tags: 'mi cartelera no está oredenada - desordenada'
    },
    {
        titulo: 'Franja verde en pantalla',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/2',
        hint: '¿Te aparece una franja verde en los margenes de la pantalla?',
        img: franjas_verdes,
        tags: 'me aparece un recuadro naranja - no me aparece la imagen completa - franja verde - verdes'
    },
    {
        titulo: 'No están las redes WIFI',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/3',
        hint: '¿Las redes de wifi no aparecen en pantalla?',
        img: no_hay_redes,
        tags: 'no puedo conectar a internet - no me aparecen las redes disponibles'
    },
    {
        titulo: 'Equipo funciona lento',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/3',
        hint: '¿Algo funciona lento o trabado?',
        img: anda_lento,
        tags: 'el banner no me carga, anda lento, trabado'
    },
    {
        titulo: 'Portal de noticias sin memoria',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/3',
        hint: '¿El portal de noticias figura sin memoria?',
        img: portal,
        tags: 'no me carga el portal - me figura en 3 2 1 - tres dos uno'
    },
    {
        titulo: 'Mensaje: Sin señal',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/4',
        hint: '¿Ves el mensaje "Sin Señal" en pantalla?',
        img: hdmi_sin_senal,
        tags: 'me aparece sin señal la pantalla - la tv no me reconoce el banner'
    },
    {
        titulo: 'Pantalla - Todo negro',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/4',
        hint: '¿La pantalla está completamente negra?',
        img: todo_negro,
        tags: 'la pantalla se ve en color negro - negra - televisor'
    },
    {
        titulo: 'Cable de red - Conexión',
        tags: 'conexión, conectar, internet, wifi, red, precio, ethernet, cable de red, precios, negro, negra, verificación inicial de medios a reproducir, desactualizado, actualizar, actualizados, no actualiza, contenido, precio desactualizado, logo',
        navegacion: 'soluciones/7',
        img: conexion_red,
        hint: '¿Conectas tus Equipos Banner Director por cable de red - Ethernet?',
        tags: 'como conecto el cable de red - como se si el banner ya está recibiendo internet por cable'
    },

]


// FUNCIÓN CHATBOT
// export const sendMessage = (message) => {
//     const url = 'https://api.openai.com/v1/chat/completions'

//     const headers = {
//         'Content-type': 'application/json',
//         'Authorization': `Bearer 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`
//     }

//     const initialMessage = {
//         role: 'system',
//         content: faqs_soporte_tecnico
//     };

//     const messages = chatLog.map(chatMessage => ({
//         role: chatMessage.type === 'user' ? 'user' : 'assistant',
//         content: chatMessage.message
//     }));

//     if (message) {
//         messages.unshift(initialMessage);
//         messages.push({ role: 'user', content: message });
//     }

//     const data = {
//         model: 'gpt-3.5-turbo-0301',
//         messages: messages
//     }

//     setIsLoading(true);

//     axios.post(url, data, { headers: headers })
//         .then((response) => {
//             setChatLog((prevChatLog) => [...prevChatLog, { type: 'bot', message: response.data.choices[0].message.content }]);
//             updateChat(id, response.data.choices[0].message.content, true)
//             setIsLoading(false);
//         })
//         .catch((error) => {
//             console.error('Error:', error);
//             setIsLoading(false);
//         });
// }
