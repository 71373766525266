import { Icon } from '@iconify/react';
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { verificarLogFechaConexion } from '../../../Utils/Others';
import { Form } from 'react-bootstrap';

function EquiposPorValidacionCuil({ modalValidacionCuil, handleCloseModalValidacionCuil, cuilValidado, handleSeleccionarEquipoYEnviarASoporte, setBusquedaPorUbicacion, busquedaPorUbicacion }) {


    console.log(cuilValidado);
    return (
        <Modal className='pt-5' show={modalValidacionCuil}>

            <Modal.Header>
                <div className='col-9'>
                    <span style={{ fontSize: 18 }}><strong>{cuilValidado?.razon.toUpperCase()}</strong></span>
                </div>
                <div className='col-1'>
                    <Icon className='pointer' onClick={handleCloseModalValidacionCuil} icon={'solar:close-circle-outline'} width={25} />
                </div>
            </Modal.Header>

            <Modal.Body>
                <span>Estado de tus equipos</span>

                <Form.Control onChange={(e) => setBusquedaPorUbicacion(e.target.value)} className='mb-2' type='text' placeholder='Buscar por ubicación' />

                {
                    cuilValidado?.ubicaciones.map((ubicacion) => (
                        ubicacion?.equipos?.
                            filter(() => ubicacion?.nombre?.toLowerCase().includes(busquedaPorUbicacion.toLowerCase())).
                            map((equipo) => (

                                <div className='border border-3 border-light p-3 rounded mb-2'>

                                    <div className='d-flex align-items-center mb-1'>
                                        <div>
                                            <Icon icon={'solar:pin-circle-bold'} width={27} color='lightgray' className='me-1' />
                                        </div>
                                        <span style={{ fontSize: 13 }}>{ubicacion.codigo_interno && ubicacion.codigo_interno + " - "} {ubicacion.nombre}</span>
                                    </div>

                                    <p style={{ fontSize: 13 }}>{equipo.plantilla} - {equipo.posicion}</p>

                                    {verificarLogFechaConexion(equipo.log_fecha)
                                        ?
                                        <div className='p-2 rounded shadow'>

                                            <div className="text-center">
                                                <div className="me-2">
                                                    <Icon className='ms-2' icon={'ic:outline-wifi-off'} width={20} color='salmon' />
                                                    <span style={{ fontSize: 12 }} className='ms-1'>Sin internet</span>
                                                </div>
                                            </div>

                                            <div className="my-2 d-flex justify-content-center align-items-center">

                                                <div className='me-1'>
                                                    <a target='_blank' href="https://soluciones.bannerdirector.com/soluciones/1">
                                                        <Button variant='danger' size='sm'>Conectar equipo</Button>
                                                    </a>
                                                </div>

                                                <div>
                                                    <Button onClick={() => handleSeleccionarEquipoYEnviarASoporte(cuilValidado.razon, equipo.macs, ubicacion.codigo_interno)} variant='success' size='sm'>
                                                        <div>
                                                            Soporte Técnico
                                                        </div>
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                        :

                                        <div className='p-2 rounded shadow d-flex align-items-center justify-content-center'>
                                            <div className="me-2">
                                                <Icon className='ms-2' width={20} icon={'solar:check-circle-bold'} color='#0DA500' />
                                                <span style={{ fontSize: 12 }} className='ms-1'>Conectado</span>
                                            </div>
                                            <div>
                                                <Button onClick={() => handleSeleccionarEquipoYEnviarASoporte(cuilValidado.razon, equipo.macs, ubicacion.codigo_interno)} variant='success' size='sm'>Soporte Técnico</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                    ))
                }
            </Modal.Body>

            <Modal.Footer className='d-flex align-items-center justify-content-between'>
                <Icon className='pointer' onClick={handleCloseModalValidacionCuil} icon={'solar:close-circle-outline'} width={25} />
            </Modal.Footer>
        </Modal >)
}

export default EquiposPorValidacionCuil