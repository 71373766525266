import { initializeApp } from "firebase/app";
import { addDoc, arrayUnion, collection, doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';


const firebaseConfig = {
    apiKey: "AIzaSyBT0IySI8LoDfH87YJXPbMfq9e8xwIqoBE",
    authDomain: "bannerchat.firebaseapp.com",
    projectId: "bannerchat",
    storageBucket: "bannerchat.appspot.com",
    messagingSenderId: "441454951466",
    appId: "1:441454951466:web:8c1c20438bc782eb665753"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);

export const addNewChat = async (chat, setter) => {
    try {
        const docRef = await addDoc(collection(db, 'Chats'), chat)
        return docRef.id
    } catch (error) {
        alert(error)
    }
}


export const updateChat = async (chatId, message, bot) => {
    try {
        await updateDoc(doc(db, 'Chats', chatId), {
            messages: arrayUnion({
                message: message,
                date: new Date(),
                bot: bot
            })
        })
    } catch (error) {
        alert(error)
    }
}

export const updateChatDocument = async (chatId, conversationKey) => {
    try {
        await updateDoc(doc(db, 'Chats', chatId), {
            conversationKey: conversationKey
        })
    } catch (error) {
        alert(error);
    }
}

export const setPersonalizedAtention = async (chatId) => {
    try {
        await updateChatMessageManual(chatId)
        await updateDoc(doc(db, 'Chats', chatId), {
            personalizedAtention: {
                asked: true,
                askedDate: new Date()
            },
        }
        )
        alert('Ok te atenderemos personlamente.')
    } catch (error) {
        alert(error)
    }
}

export const obtenerChats = (colName, setter) => {

    const colRef = collection(db, colName)

    onSnapshot(colRef, (q) => {
        const datos = [];
        q.docs.forEach((doc) => {
            datos.push({ ...doc.data(), id: doc.id });
        });
        setter(datos);
    });
};

export const getThisChat = (id, setter) => {
    const docRef = doc(db, 'Chats', id);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
            setter({ ...docSnap.data(), id: docSnap.id });
        } else {
            alert("No such document!");
        }
    });
    return unsubscribe;
}

export const updateChatManualAtention = async (chatId) => {
    try {
        await updateDoc(doc(db, 'Chats', chatId), {
            manualAtentionActivated: true,
            isBeingAtended: true
        })
    } catch (error) {
        alert(error)
    }
}

export const updateChatMessageManual = async (chatId) => {
    try {
        await updateDoc(doc(db, 'Chats', chatId), {
            messages: arrayUnion({
                message: 'Estamos trabajando en tu caso y uno de nuestros especialistas humano te atenderá a la brevedad.',
                date: new Date(),
                bot: true
            })
        })
    } catch (error) {
        alert(error)
    }
}

export const sendChatMessage = async (message, chatId) => {
    try {
        await updateDoc(doc(db, 'Chats', chatId), {
            messages: arrayUnion({
                message: message,
                date: new Date(),
                bot: true
            })
        })
    } catch (error) {
        alert(error)
    }
}



export const actualizarDocumento = async (coleccion, id, url) => {
    const ref = doc(db, coleccion, id)
    await updateDoc(ref, {
        messages: arrayUnion({
            bot: false,
            date: new Date(),
            img: url
        }),
    })
}

export const sendImageInChat = async (file, id) => {
    try {
        const storageRef = ref(storage, uuid())
        await uploadBytes(storageRef, file)
        const url = await getDownloadURL(storageRef)
        await actualizarDocumento('Chats', id, url)
    } catch (error) {
        alert(error)
    }

}