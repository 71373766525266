import { Icon } from '@iconify/react'
import React from 'react'
import cerrarAplicacion from "../Assets/Recursos/Soluciones_cerrar_aplicacion.svg"
import perilla_switch from "../Assets/Perillita.png"
import btnBanner from "../Assets/Recursos/Soluciones_control_1.svg"

function ExplicacionConexion({ handleEnviarInfoASoporte }) {
    return (
        <div style={{ fontSize: 13 }} className='shadow p-2 rounded'>

            <div className="col-12 mx-auto text-center pb-3">
                <iframe width="100%" style={{ aspectRatio: "16 / 9" }} className="rounded" src={'https://www.youtube.com/embed/7nJyJGTHVT4?si=ak1lA67FFwaeXLSK'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <ol>
                <h6 className='pb-2'>Conectar equipo a internet</h6>
                <li>
                    <div className="d-flex mb-3">
                        <span>
                            Presiona el botón <strong>Menu</strong> del control remoto.
                        </span>
                        <div className="col-2 text-center">
                            <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'solar:hamburger-menu-linear'} />
                        </div>
                    </div>
                </li>

                <li>
                    <div className="mb-3 d-flex">
                        <div className="col-7">
                            <span>Presiona <strong>OK</strong> en la opción <strong>Cerrar aplicación.</strong></span>
                        </div>
                        <div className="col-5 text-end">
                            <img src={cerrarAplicacion} width={100} />
                        </div>
                    </div>
                </li>

                <li>
                    <div className="d-flex mb-3 align-items-center">
                        <span>
                            Cuando se cierre el reproductor, presiona  el <strong>botón de WIFI</strong> del control remoto.
                        </span>
                        <div className="col-2 text-center">
                            <Icon color="white" className="bg-secondary p-1 rounded" width={30} icon={'ic:round-wifi'} />
                        </div>
                    </div>
                </li>


                <li>
                    <div className="mb-3">
                        <div>
                            <span>Busca tu red de wifi, presiona <strong>OK</strong> e ingresa la contraseña.</span>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="mb-3">
                        <div>
                            <span>Si no figuran redes disponibles, prueba presionando el botón <strong>Switch de Encendido</strong> dos veces, para cambiar el <strong>Estado</strong>. Puedes hacerlo con el <strong>botón Mouse</strong> del control remoto.</span>
                        </div>
                        <div className="text-center">
                            <img src={perilla_switch} width={200} className="rounded" />
                        </div>

                    </div>
                </li>

                <li>
                    <div className="mb-3 d-flex">
                        <div>
                            <span>Si tu reproductor todavía no se reinició, presiona el <strong>botón amarillo Banner Director</strong>.</span>
                        </div>
                        <div className="text-start">
                            <img src={btnBanner} width={150} />
                        </div>
                    </div>
                </li>
            </ol>

            <p className='text-center pointer' style={{ fontStyle: 'italic', color: 'blue', fontSize: 15, textDecoration: 'underline' }} onClick={handleEnviarInfoASoporte}>
                ¿Todavía no pudiste solucionarlo?
            </p>

        </div>
    )
}

export default ExplicacionConexion