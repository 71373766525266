import "./ClientValidation.css"
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { addNewChat } from "../../../Utils/Firebase"
import { eliminarDosPuntos, normalizeCategoryProblem, obtenerEquipoPorCodigoMac, obtenerEquipoPorCuil, validarIdentidad } from "../../../Utils/Others"
import { AppContext } from "../../../Utils/Context"
import ClientInfoModal from "./Components/ClientInfoModal"

import header_azul from "../../../Assets/Header_AzulF.png"
import TituloMasLogo from "../../../Components/TituloMasLogo"
import DropdownEquipos from "./Components/DropdownEquipos"
import { Button, Form, Spinner } from "react-bootstrap"
import MenuNav from "../../../Components/MenuNav"
import ModalMacInorrect from "./Components/ModalMacInorrect"
import CUITClientValidation from "./CUITClientValidation"
import EquiposPorValidacionCuil from "./EquiposPorValidacionCuil"

function ClientValidation() {

    const navigate = useNavigate()
    const { problemCategory, setProblemCategory } = useContext(AppContext)
    const handleClose = () => setShowClientValidationOk(false);
    const handleCloseModalValidacionCuil = () => setModalValidacionCuil(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isLoading2, setIsLoading2] = useState(false)

    const [cuilValidado, setCuilValidado] = useState(null)

    const [showClientValidationOk, setShowClientValidationOk] = useState(false);

    const [clientSelectedDevice, setClientSelectedDevice] = useState(null)
    const [clientDevicesList, setClientDevicesList] = useState([])

    const [fullClient, setFullClient] = useState(null)
    const [clientName, setClientName] = useState('')

    const initialMessage = [
        { bot: true, message: `¡Hola ${clientName || ''} 👋! Bienvenido al asistente de Banner Director.`, date: new Date() }
    ]

    const [clientMacAddress, setClientMacAddress] = useState('')

    const [modalMacIncorrect, setModalMacIncorrect] = useState(false)
    const [modalIngresoCuil, setModalIngresoCuil] = useState(false)

    const [cuilIngresado, setCuilIngresado] = useState('')

    const [busquedaPorUbicacion, setBusquedaPorUbicacion] = useState('')

    const handleTalkWithAIAssistant = async () => {

        if (clientSelectedDevice) {
            const newChatId = await addNewChat({
                date: new Date(),
                messages: initialMessage,
                macAddress: clientMacAddress,
                problemCategory: problemCategory,
                client: fullClient.razon
            });

            if (newChatId) {
                navigate(`/asistenciachat/${newChatId}`);
            } else {
                alert('Error al crear el chat');
            }
        }
    }

    const handleIngresarConCuil = () => {
        setModalMacIncorrect(false)
        setModalIngresoCuil(true)
    }

    const handleCloseModal = () => {
        setModalIngresoCuil(false)
    }

    const [codigos, setCodigos] = useState(null)

    const handleValidarPorMac = async () => {

        setIsLoading(true)
        const codigos = await validarIdentidad('mac', clientMacAddress)

        setTimeout(() => {
            if (codigos?.ubicaciones) {

                for (let ubicacion of codigos.ubicaciones) {
                    const equipo = ubicacion.equipos.find(e => eliminarDosPuntos(e.macs.toLowerCase()).includes(eliminarDosPuntos(clientMacAddress.toLowerCase())))
                    if (equipo) {
                        setClientDevicesList(ubicacion.equipos);
                        setFullClient(codigos);
                        setClientSelectedDevice(equipo);
                        break;
                    }
                }

                setCodigos(codigos)
                setShowClientValidationOk(true);
                setIsLoading(false)
            } else {
                setModalMacIncorrect(true)
                setIsLoading(false)
            }
        }, 700);
    }

    const validarIngresoPorCuil = async () => {

        setIsLoading2(true)

        const codigos = await validarIdentidad('codigo_interno', cuilIngresado)

        if (codigos) {
            setCuilValidado(codigos)
            setModalIngresoCuil(false)
            setModalMacIncorrect(false)
            setModalValidacionCuil(true)
            setIsLoading2(false)
        } else {
            // setModalMacIncorrect(true)
            alert('El CUIL/CUIT no está asociado a Banner Director. Intenta de nuevo.')
            setIsLoading2(false)

        }
        // setClientMacAddress('')
        setIsLoading2(false)
    }

    const [modalValidacionCuil, setModalValidacionCuil] = useState(false)

    const hablarConSoporteTecnico = () => {
        window.open(`https://api.
        .com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Soy de la razón social: ${fullClient?.razon}. Escribí mi Código MAC y el equipo figura conectado a internet correctamente. Mi código MAC es ${clientSelectedDevice?.macs}. `)}`);
    }

    const hablarConSoportTecnicoCuil = () => {
        window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Soy de la razón social: ${cuilValidado?.razon}. ¿Pueden ayudarme? Esta es la descripción de mi problema: `)}`);
    }
    const [selectedCUILDevice, setSelectedCUILDevice] = useState(null)

    const consultaCompletaSoporteTecnico = () => {
        window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Soy de la razón social: ${fullClient?.razon}. Escribí mi Código MAC y mi equipo figura conectado a internet correctamente. ¿Pueden ayudarme? Mi Código MAC es: ${selectedCUILDevice.macs} `)}`);
    }

    const handleSeleccionarEquipoYEnviarASoporte = (razon, mac, codigo_interno) => {
        if (codigo_interno) {
            window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Soy de la razón social: ${razon}. El código MAC de mi equipo es ${mac} y el código interno es ${codigo_interno}. `)}`);
        } else {
            window.open(`https://api.whatsapp.com/send?phone=+5493516660798&text=${encodeURIComponent(`¡Hola! Soy de la razón social: ${razon}. El código MAC de mi equipo es ${mac}. `)}`);
        }
    }


    return (
        <div>

            <div className="text-center">
                <img src={header_azul} width={window.innerWidth < 1000 ? '100%' : '30%'} />
            </div>

            <div className={`${window.innerWidth < 1000 ? 'col-11' : 'col-5'} bg-light mt-3 mx-auto py-3 px-2 rounded`}>
                <TituloMasLogo solucion={'Validación de identidad'} />
                <p className="p-1" style={{ color: '#061232', fontSize: 14 }}>
                    Para una mejor atención, te pedimos que <strong>ingreses el Código MAC Address</strong> de tu equipo (12 caracteres). Esto nos ayudará a solucionar tu problema mucho más rápido.
                </p>

                <div className="my-2">
                    <DropdownEquipos />
                </div>

            </div>

            <div className={`${window.innerWidth < 1000 ? 'col-11' : 'col-5'} rounded py-4 mb-3 mx-auto mt-3`} style={{ backgroundColor: '#051131' }}>
                <div className="col-10 mx-auto py-2">
                    <Form.Control style={{ height: 30, fontSize: 16 }} type="text" placeholder="Ingresar Código MAC Address" className="mt-3" onChange={(e) => setClientMacAddress(e.target.value)} />

                    {
                        !isLoading ?
                            <div>
                                <div className="text-center mt-2">
                                    <button onClick={handleValidarPorMac} style={{ border: 'none', backgroundColor: 'orangered' }} className="text-light rounded py-1">Validar</button>
                                </div>


                            </div>
                            :
                            <div className="text-center mt-2">
                                <Spinner style={{ color: 'whitesmoke' }} animation="grow" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                    }

                </div>
            </div>

            <div className="text-center mt-2">
                <Button style={{ border: 'none', backgroundColor: 'orangered', borderRadius: 25 }} onClick={handleIngresarConCuil}>Ingresar con CUIL/CUIT</Button>
            </div>

            <MenuNav />

            <ClientInfoModal codigos={codigos} devicesList={clientDevicesList} selectedDevice={clientSelectedDevice} handleTalkWithAIAssistant={handleTalkWithAIAssistant} handleClose={handleClose} show={showClientValidationOk} fullClient={fullClient} hablarConSoporteTecnico={hablarConSoporteTecnico} />

            <ModalMacInorrect modalMacIncorrect={modalMacIncorrect} handleIngresarConCuil={handleIngresarConCuil} setModalMacIncorrect={setModalMacIncorrect} />

            <CUITClientValidation isLoading={isLoading2} setIsLoading={setIsLoading2} validarCuilIngresado={validarIngresoPorCuil} cuilIngresado={cuilIngresado} setCuilIngresado={setCuilIngresado} modalIngresoCuil={modalIngresoCuil} handleClose={handleCloseModal} />

            <EquiposPorValidacionCuil busquedaPorUbicacion={busquedaPorUbicacion} setBusquedaPorUbicacion={setBusquedaPorUbicacion} handleSeleccionarEquipoYEnviarASoporte={handleSeleccionarEquipoYEnviarASoporte} selectedCUILDevice={selectedCUILDevice} setSelectedCUILDevice={setSelectedCUILDevice} consultaCompletaSoporteTecnico={consultaCompletaSoporteTecnico} hablarConSoportTecnicoCuil={hablarConSoportTecnicoCuil} handleCloseModalValidacionCuil={handleCloseModalValidacionCuil} modalValidacionCuil={modalValidacionCuil} cuilValidado={cuilValidado} />

        </div>
    )
}

export default ClientValidation